<template>
    <CustomLoader v-if="pageLoading" height="100vh" />
    <div v-else class="py-20 personal-info-page">
        <div class="row col-12 mx-0">
            <div class="col-lg-4">
                <h2>{{ $t("Let us get to know you") }}</h2>
                <p>{{ $t("This helps in creating your personalised profile") }}</p>
            </div>
            <div class="col-lg-4 text-center">
                <img class="w-50" src="/media/logos/buying/blue-logo-buying-teams.svg" alt="">
            </div>
            <div class="col-lg-4 d-flex justify-content-end align-items-center">
                <p class="mb-0 me-3">{{ $t("Existing User ?") }}</p>
                <router-link to="/sign-in">{{ $t("Log in") }}</router-link>
            </div>
        </div>

        <div class="px-10 mt-10">
            <el-form
                class="setupForm"
                ref="personalInfoForm"
                :model="personalInfoForm"
                :rules="personalInfoRules">
                <el-form-item prop="first_name">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("First Name") }}</label>
                    <el-input
                        :placeholder="$t('Enter your First Name')"
                        v-model="personalInfoForm.first_name"></el-input>
                </el-form-item>
                <el-form-item prop="last_name">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Last Name") }}</label>
                    <el-input
                        :placeholder="$t('Enter your Last Name')"
                        v-model="personalInfoForm.last_name"></el-input>
                </el-form-item>
                <el-form-item prop="product_areas">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Product Areas") }}</label>
                    <el-select
                        multiple
                        collapse-tags
                        v-model="personalInfoForm.product_areas"
                        :placeholder="$t('Select which Bank Product Areas you work with')">
                        <el-option
                            v-for="(productArea, index) in allProductAreas"
                            :key="index"
                            :label="productArea"
                            :value="productArea"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="job_title">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Job Title") }}</label>

                    <el-input
                        :placeholder="$t('Please Input')"
                        v-model="personalInfoForm.job_title"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="country">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Country of Residence")
                        }}</label>
                    <el-select
                        v-model="personalInfoForm.country"
                        filterable
                        :placeholder="$t('Select your Country of your residence')">
                        <el-option
                            v-for="(item,index) in countries"
                            :key="index"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

<!--                <el-form-item prop="language">-->
<!--                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Preferred Language") }}</label>-->
<!--                    <el-select-->
<!--                        v-model="personalInfoForm.language"-->
<!--                        class="preferred-language"-->
<!--                        popper-class="select-language-dropdown"-->
<!--                        :placeholder="$t('Select your Country of your residence')">-->
<!--                        <template #prefix>-->
<!--                            <img :src="supportedLanguages[personalInfoForm.language].icon_path" width="20" height="20" alt="">-->
<!--                            <span>{{ supportedLanguages[personalInfoForm.language].title }}</span>-->
<!--                        </template>-->
<!--                        <el-option-->
<!--                            v-for="(item, value) in supportedLanguages"-->
<!--                            :key="value"-->
<!--                            :value="value"-->
<!--                        >-->
<!--                            <div class="select-language__item">-->
<!--                                <div class="select-language__item&#45;&#45;icon">-->
<!--                                    <img :src="item.icon_path" alt="">-->
<!--                                </div>-->
<!--                                <p>{{ item.title }}</p>-->
<!--                                <span>{{ item.subTitle }}</span>-->
<!--                            </div>-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->

            </el-form>
            <hr class="my-15">
            <div class="row d-flex justify-content-between mt-15">
                <div class="col-lg-4">
                    <h2>{{ $t("Choose your Banks") }}</h2>
                    <p>{{ $t("Choose which banks, and which countries of the banks you work with") }}</p>
                </div>
                <div class="col-lg-4 d-flex justify-content-end align-items-center">
                    <p class="mb-0 me-3" v-if="getAddedBanksLength">{{ getAddedBanksLength }} {{ $t("Added") }}</p>
                    <button @click="addBanksForm" class="btn-light-primary add-btn">
                        <img width="15" class="me-3" src="/media/buying/icons/add.svg" alt="">
                        <b>{{ $t("Add Bank") }}</b>
                    </button>
                </div>
            </div>

            <el-form
                v-for="(bankField, i) in banksForm"
                :key="i"
                class="setupForm my-10 setupForm-select-bank"
                :ref="'bankFieldForm-' + i"
                :model="bankField"
                :rules="banksRules">
                <el-form-item prop="bank_name">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Bank Name") }}</label>
                    <el-select
                        v-model="bankField.bank_name"
                        filterable
                        placeholder="Select"
                    >
                        <el-option
                            v-for="(item, index) in banks"
                            :key="index"
                            :label="item.name"
                            :value="item.name"
                        ></el-option>
                    </el-select>

                </el-form-item>
                <div v-if="i !== 0" class="mt-10 cursor-pointer" @click="removeForm(i)">
                    <img src="/media/buying/icons/removeField.svg" alt="">
                </div>

                <el-form-item prop="countries" class="filterable-select">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Bank Country") }}</label>
                    <el-select
                        v-model="bankField.countries"
                        collapse-tags
                        multiple
                        filterable
                        :placeholder="$t('All Countries')"
                    >
                        <el-option
                            v-for="(item,index) in countries"
                            :key="index"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <button
                :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
                id="kt_sign_in_submit"
                class="btn btn-lg main-btn w-100 my-5 mt-20 d-block m-auto"
                @click="save()">
                <span class="indicator-label"> {{ $t("Save") }} </span>

                <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
            </button>
            <div class="d-flex justify-content-center align-items-center mt-5">
                <a class="privacy-policy me-3 pe-3 border-end" :href="platformConfigs.platformLinks.Terms" target="_blank">
                    {{ $t("Terms & Conditions") }}
                </a>
                <a class="privacy-policy" :href="platformConfigs.platformLinks.Privacy" target="_blank">
                    {{ $t("Privacy Policy") }}
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent } from "vue";
import DashboardFilter from "@/buying-teams/shared-components/dashboard/DashboardFilter.vue";
import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { DataService } from "@/core/services/DataService";
import { Mutations } from "@/store/enums/StoreEnums";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { SUPPORTED_LANGUAGES } from "@/core/config/constants";

export default defineComponent({
    name: "sign-in",
    components: {
        CustomLoader,
        DashboardFilter,
    },

    data() {
        let buttonIndicator = "off";
        let validBanks = false;
        const banksForm = [
            {
                bank_name: "",
                countries: []
            }
        ];
        const banksRules = {
            bank_name: [
                { required: true, message: this.$t("Please input bank name"), trigger: "change" }
            ],
            countries: [
                { required: true, message: this.$t("Please select country"), trigger: "change" }
            ]
        };

        const personalInfoForm = {
            first_name: "",
            last_name: "",
            product_areas: [],
            job_title: "",
            business_banks: [],
            country: "",
            // language: "en"
        };
        const personalInfoRules = {
            first_name: [
                { required: true, message: this.$t("Please input First name"), trigger: "change" },
                { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
            ],
            last_name: [
                { required: true, message: this.$t("Please input Last name"), trigger: "change" }
            ],
            product_areas: [
                { required: true, message: this.$t("Please select Product Area"), trigger: "change" }
            ],
            job_title: [
                { required: true, message: this.$t("Please input Job Title"), trigger: "change" }
            ],
            country: [
                { required: true, message: this.$t("Please select Country of Residence*"), trigger: "change" }
            ],
            // language: [
            //     { required: true, message: this.$t("Please select Country of Residence*"), trigger: "change" }
            // ]

        };

        return {
            validBanks,
            banksForm,
            banksRules,
            personalInfoForm,
            personalInfoRules,
            buttonIndicator,
            pageLoading: true,
            platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS),
            supportedLanguages: SUPPORTED_LANGUAGES,
        };
    },

    computed: {
        allProductAreas() {
            return store.getters.allProductAreas;
        },
        banks() {
            return store.getters.allBanks;
        },
        countries() {
            return store.getters.allCountries;
        },
        getAddedBanksLength() {
            return this.banksForm.length ? this.banksForm.filter(b => b.bank_name && b.countries.length).length : 0;
        }
    },

    async mounted() {
        await this.getStaticData();
        this.personalInfoForm.product_areas = this.allProductAreas;
    },

    methods: {
        getFilteredCountries(bankName) {
            if (this.banks.length) {
                return this.banks.filter(bank => bank.name === bankName);
            }

            return [];
        },
        addBanksForm() {
            this.banksForm.push({
                bank_name: "",
                countries: []
            });
        },
        removeForm(index) {
            this.banksForm.splice(index, 1);
        },

        validateBanks() {
            for (let i = 0; i < this.banksForm.length; i++) {
                this.$refs["bankFieldForm-" + i][0].validate((valid) => {
                    if (valid) {
                        this.validBanks = true;
                    } else {
                        return false;
                    }
                });
            }
        },

        save() {
            this.validateBanks();
            let validPersonalInfo = false;
            this.$refs.personalInfoForm.validate((valid) => {
                if (valid) {
                    validPersonalInfo = true;
                } else {
                    return false;
                }
            });

            if (validPersonalInfo && this.validBanks) {
                this.personalInfoForm.business_banks = this.banksForm;

                this.buttonIndicator = "on";
                store.dispatch("setupBusinessUser", this.personalInfoForm)
                    .then(res => {
                        if (res.status === 200) {
                            store.commit("SAVE_BUSINESS_INFO", res.data.company);
                            // TODO check SET_AUTH functionality
                            store.commit(Mutations.SET_AUTH, res.data.company);
                            store.commit(Mutations.SET_USER, res.data.info);
                            DataService.storeUserFirstLogin();

                            window.location.href = "/dashboard";
                        }
                    })
                    .catch(error => {
                        NotificationService.swalError(error);
                    })
                    .finally(_ => {
                        this.buttonIndicator = "off";
                    });
            }
        },

        getStaticData() {
            return Promise.all([
                store.dispatch("fetchStaticData", StaticDataEnum.PRODUCT_AREAS),
                store.dispatch("fetchStaticData", StaticDataEnum.BANKS),
                store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES)
            ]).finally(() => {
                this.pageLoading = false;
            });
        }
    }
});
</script>
<style lang="scss">
.personal-info-page {
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
    }

    p {
        font-size: 16px;
        line-height: 29px;
        color: #434343;
        opacity: 0.6
    }

    .add-btn {
        background: rgba(67, 91, 244, 0.06) !important;
        border-radius: 56px;
        padding: 10px 25px !important;
        border: none;
    }

    .filterable-select {
        .el-select__tags {
            position: revert;
            transform: revert;

            > span {
                position: absolute;
                bottom: -36px;
            }

            .el-select__input {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100% !important;
                z-index: 9;
            }
        }
    }
}

.setupForm.setupForm-select-bank {
    flex-wrap: nowrap;

    @media (max-width: 1200px) {
        .el-form-item {
            width: 43%;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        border-top: 1px solid #eaeaea;
        .el-form-item {
            width: 100%;
        }
        .mt-10.cursor-pointer {
            margin-top: 0 !important;
        }
    }
}
</style>
